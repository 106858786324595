import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';
import { GlobalLink } from '@components/Link';
import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';
import { ReactComponent as Logo } from '@images/logos/logo-rocketbatch.svg';
const NAV_ITEMS = [
  /*{
    key: 0,
    name: 'About',
    link: '/#about',
  },*/
  {
    key: 0,
    name: 'Features',
    link: '/#features',
  },
  {
    key: 1,
    name: 'Our Story',
    link: '/story',
  },
  
  /*{
    key: 2,
    name: 'Download',
    link: '/downloads',
  },
  {
    key: 3,
    name: 'Pricing',
    link: '/pricing',
  },*/
  
];


class NavbarSubpages extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

    getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      
      <Scrollspy
        items={NAV_ITEMS.map(item => item.name.toLowerCase())}
        
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem.key}><GlobalLink to={navItem.link}>{navItem.name}</GlobalLink></NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props} style={{background:"#E6E9EC"}}>
        <StyledContainer >
          
          {/*
          <Brand as="a" href="/">rocketbatch</Brand>
          */} 

          <Brand as="a" href="/"><Logo style={{height:"40px",width:"40px"}}/></Brand> 
          

          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: '#F22789' }}>
              <MenuIcon />
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default NavbarSubpages;
